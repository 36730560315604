import { graphql, Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

//import Post from 'templates/post'
import Meta from 'components/meta'
import Layout from 'components/layout'
import Footer from 'components/footer/index'

import logo from '../dist/img/logo/logo_white.png'

const fourofour = ({ location }) => {
  return (
    <Layout location={location}>
      <link
        href="https://maxcdn.bootstrapcdn.com/font-awesome/4.3.0/css/font-awesome.min.css"
        rel="stylesheet"
      />
      <div class="error-header"> </div>
      <div class="container ">
        <section class="error-container text-center">
          <h1>404</h1>
          <div class="error-divider">
            <h2>PAGE NOT FOUND.</h2>
            <p class="description">We Couldn't Find This Page</p>
          </div>
          <Link to="/en/" class="return-btn">
            <i class="fa fa-home"></i>Home
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default fourofour
